import React, { createContext, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';

export const ScrollContext = createContext();

export const ScrollContextProvider = ({ children }) => {
  const [scrollY, setScrollY] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const router = useRouter();

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    setScrollY(currentScrollY);
    setIsBottom(currentScrollY + windowHeight >= documentHeight - 50); // Add a small buffer
    setIsScrolling(true);
    handleScrollEnd();
  }, []);

  const handleScrollEnd = useCallback(debounce(() => {
    setIsScrolling(false);
  }, 200), []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0); // Reset scroll position to top
      setScrollY(0);
      setIsBottom(false); // Reset isBottom to false
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ScrollContext.Provider value={{ scrollY, isScrolling, isBottom }}>
      {children}
    </ScrollContext.Provider>
  );
};
