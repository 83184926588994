/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import {ScrollContextProvider} from "@components/Context/ScrollContextProvider";
import "../styles/globals.css";
import Head from "next/head";
import React from "react";
import FaviconManager from "@components/FaviconManager";


function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="keywords"
          content="ABA therapy, ADHD symtoms, Childcare, Cause of autism, Early signs of autism, adhd treatments, Best child development center, adhd treatments, autism therapy, Autism treatment center, Type of ADHD, Learning Disorder "
        />
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,700;1,400&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&family=Oswald:wght@700&display=swap');
        </style>

      </Head>
      <ScrollContextProvider>
        <FaviconManager />
        <Component {...pageProps} />
      </ScrollContextProvider>
    </>

  );
}


export default MyApp;
