import { useEffect } from 'react'
import { useRouter } from 'next/router'

export function useFavicon() {
  const router = useRouter()

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]')
    if (!favicon) return
    const host=window.location.host

    // Change favicon based on route or domain
    if (host === 'localhost:10004' || host.includes("sunshine")) {
        favicon.href = '/Favicon_sunshine.png'
    } else {
      favicon.href = '/emu-favicon.svg'
    }
  }, [router.pathname])
}