import { useFavicon } from 'hooks/useFavIcon'
import React from 'react'

function FaviconManager() {
  useFavicon()
  return (
    <></>
  )
}

export default FaviconManager